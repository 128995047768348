module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.01,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://graphql.contentful.com/content/v1/spaces/3tapvt0o6lko/environments/master","headers":{"Authorization":"Bearer XzMyz2dbSnWw8Mfcq4c9g9ZBZ1HYW5FR39Y2DMyH0so"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-6032190-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://fa5dfc52b4004385a816ffdfecde73cf@o29651.ingest.sentry.io/5538650","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ALLPRESS","short_name":"allpress","start_url":"/","background_color":"#EFE7DD","theme_color":"#EFE7DD","icon":"src/images/icon.png","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"dd795770c61b287d2b525d541158307c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/anthonyvanbeek/sites/pixelfusion/allpress/allpress-website/src/intl","languages":["en","ja"],"defaultLanguage":"en"},
    },{
      plugin: require('../plugins/gatsby-source-instagram-custom/gatsby-browser.js'),
      options: {"plugins":[],"instagramId":979886805,"mediaCount":10},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":80},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
